import React, { useEffect, useRef } from 'react';

import Icon from '@components/Icon';

import './SubscriptionsResult/SubscriptionResultStyles.css';

import { editSubscriptionRecord } from '@domain/my-donations/constants';

import getDonateWidgetMode from '@utils/getDonateWidgetMode';

import {
  DONATE_WIDGET_URL_PROD,
  DONATE_WIDGET_URL_UAT,
  DRUPAL_ENTITY_ID,
  MY_CMS_URL_PROD,
  MY_CMS_URL_UAT,
} from '@constants/index';

import { useTranslation } from '@external/react-i18next';

import { DonateWidget } from '@typings/widget';

interface EditSubscriptionModalProps {
  onCloseEditModal: () => void;
  subscriptionEditRecord: editSubscriptionRecord;
}
const EditSubscriptionModal: React.FC<EditSubscriptionModalProps> = ({
  onCloseEditModal,
  subscriptionEditRecord,
}) => {
  const modalContainerRef = useRef<HTMLDivElement | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const donateWidgetRef = useRef<HTMLDivElement | null>(null);
  const scriptRef = useRef<HTMLScriptElement | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (
      !donateWidgetRef.current ||
      !modalContainerRef.current ||
      !modalRef.current
    )
      return;

    if (document.getElementById('dynamic-content-script')) return;

    const script = document.createElement('script');
    script.id = 'dynamic-content-script';
    script.setAttribute('data-testid', 'content-script-dynamic');
    script.type = 'text/javascript';
    script.src =
      process.env.GATSBY_ENV === 'prod'
        ? DONATE_WIDGET_URL_PROD
        : DONATE_WIDGET_URL_UAT;
    scriptRef.current = script;

    script.onload = () => {
      const data = subscriptionEditRecord;
      const donateWidget = () =>
        (window.donateWidget as DonateWidget)(
          process.env.GATSBY_ENV === 'prod' ? MY_CMS_URL_PROD : MY_CMS_URL_UAT,
          DRUPAL_ENTITY_ID,
          data,
          donateWidgetRef.current,
          getDonateWidgetMode('self-service')
        );

      const timeoutId = setTimeout(() => {
        donateWidget();
      }, 1000);
      return () => {
        clearTimeout(timeoutId);
      };
    };
    modalRef.current.appendChild(script);
    document.body.style.overflow = 'hidden';

    // eslint-disable-next-line consistent-return
    return () => {
      if (scriptRef.current) {
        scriptRef.current.remove();
        scriptRef.current = null;
      }
      document.body.style.overflow = '';
    };
  }, [subscriptionEditRecord]);

  return (
    <div className="modalEdit" ref={modalRef} data-testid="modal-edit">
      <div className="modalContainer" ref={modalContainerRef}>
        <div className="closeContainer">
          <div className="close-wrapper" onClick={onCloseEditModal}>
            <span className="close-text">{t('edit-modal-close', 'Close')}</span>
            <Icon
              className="close-button"
              name="ui/crossclose"
              size="20"
              color="bright-blue-600"
            />
          </div>
        </div>
        <div id="donate-widget" ref={donateWidgetRef} />
        <div className="closeContainer">
          <div className="close-wrapper" onClick={onCloseEditModal}>
            <span className="close-text">{t('edit-modal-close', 'Close')}</span>
            <Icon
              className="close-button"
              name="ui/crossclose"
              size="20"
              color="bright-blue-600"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSubscriptionModal;
